body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #2f4454;
  position: relative;
  /* overflow: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* FONTS */
.robot {
  font-family: 'Roboto Mono', monospace;
}
.anton {
  font-family: 'Anton', sans-serif;
}
/*  */

/* text-color */
.text-pink{
  color: #da7893;
}
.text-light{
  color: #c9d6e1;
}
.text-darker{
  color: #7d9eb6;
}
.text-light-green{
  color: #9cc2ce
}
/*  */
#nav_links{
  transition: color 0.5s;
}
#nav_links:hover{
  color: #da7893;
}
/* background */

/*  */

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.page_content{
  position: relative;
  top: 90px;
}

#navbar{
  display: flex;
  position: fixed;
  height: 90px;
  width: 100%;
  top: 0px;
  /* transition-timing-function: ease-in;
  transition:5s; */
  z-index: 2;
  transition: top 0.5s;
}
#navbar_cover{
  display: flex;
  display: flex;
  position: fixed;
  height: 90px;
  width: 100%;
  top: 0px;
  background-color: #2f4454;
  opacity: 0.9;
  z-index: -1;
  transition: top 0.5s, box-shadow 0.5s;
}

#navbar_cover.show{
  box-shadow: 1px 1px 8px #283a47;
  top: 0px;
}
#navbar_cover.hide{
  top: -90px;
}


#menu{
  z-index: 1;
}

#navbar.show{
  top: 0px;
}
#navbar.hide{
  top: -90px;
}

.screen{
  margin: 0px auto;
  width: 100%;
  max-width: 1600px;
  min-height: 100vh;
  padding: 200px 150px;
}
.screen>h3{
  width: 50%;
}

#pink_button{
  border: 1px solid #da7893;
  transition: color 1s, border 1s;
}
#pink_button:hover{
  border: 1px solid #c9d6e1;
  color: #c9d6e1;
}

.button-clicked{
  border-left-width: 2px;
  border-color: #da7893;
}


.button{
  transition: background-color 1s, color 1s;
  max-height: 50px;
  width: 190px;
}
.button:hover{
  background-color: #c9d6e1;
  color: #2f4454;
}
.button.clicked{
  background-color: #c9d6e1;
  color: #2f4454;
}

.cover{
  filter: grayscale(100%);
  transition: filter 1s;
  width: 80%;
}
.cover:hover{
  filter: grayscale(0%);
}

.description{
  background-color: #283a47;
}

.icon{
  transition: color 0.5s;
  transition: transform .5s;
}
.icon:hover{
  color: #c9d6e1;
  transform: scale(1.2);
}

#box{
  background-color: #283a47;
}

.mini_screen{
  margin: 0px auto;
  width: 100%;
  max-width: 1600px;
  min-height: 50vh;
  padding: 200px 150px;
}
.mini_screen>h3{
  width: 50%;
}

#hamburger{
  display: none;
}

.animation{
  animation: transitionIn 2s;
}

@keyframes transitionIn {
  from {
    opacity: 0;
    /* transform: rotateX(-10deg); */
  }

  to {
    opacity: 1;
    /* transform: rotateX(0); */
  }
}

#loader{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.blur{
  transition: all 0.5s;
  filter: blur(4px);
  position: fixed;
  top: 90px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity: 0.5;
  background-color: #283a47;
  visibility: hidden;
}

#mobile_menu{
  display: none;
  position: absolute;
}
#mobile_title{
  display: none;
}


@media only screen and (max-width: 1050px){
  .screen>h3{
    width: 100%;
  }
  #flex-centering{
    align-self: center;
  }
  #used_tech{
    justify-self: center;
  }
  #project_image{
    padding-left: 0px;
    justify-self: center;
  }
  .screen{
    margin: 0px auto;
    width: 100%;
    max-width: 1600px;
    min-height: 100vh;
    padding: 50px;
  }
  .mini_screen{
    margin: 0px auto;
    width: 100%;
    max-width: 1600px;
    min-height: 50vh;
    padding: 50px;
  }
  #loader{
    font-size: 50px;
  }
    /* about */
    #about_container{
      display: flex;
      flex-direction: column-reverse;
    }
    #about_container>img{
      padding-bottom: 10px;
      justify-self: start;
      padding-left: 0px;
    }
    #about{
      padding-bottom: 130px;
    }
    /*  */
    /* Experience */
    #experience_container{
      flex-direction: column;
    }
    #experience_job_title{
      flex-direction: column;
    }
    #experience{
      padding-bottom: 130px;
    }
    /*  */
    /* Project */
    #projects_container{
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
    }
    #projects_container_reverse{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-bottom: 50px;
    }
    #projects{
      padding-bottom: 130px;
    }
    #web_title{
      display: none;
    }
    #mobile_title{
      display: initial;
      text-align: center;
    }
}


@media only screen and (max-width: 756px){
  .blur{
    visibility: visible;
  }
  #sub_menu{
    display: none;
  }
  #hamburger{
    display: initial;
  }
  #mobile_menu{
    display: initial;
    position: fixed;
    top: 90px;
    width: 60%;
    height: 100%;
    right:-60%;
    text-align: center;
    z-index: 3;
    background-color: #283a47;
    transition: right 0.5s;
    /* box-shadow: 1px 1px #283a47; */
  }
  #mobile_menu.show{
    right: 0%;
  }
}

/* mobile size */
@media only screen and (max-width: 375px){
  .screen{
    padding: 20px;
    min-height: 75vh;
  }
  .mini_screen{
    padding: 20px;
  }
  /* introduction */
  #introduction>h1{
    font-size: 60px;
  }
  #introduction>h2{
    font-size: 40px;
  }
  #introduction>h3{
    width: 100%;
  }
  /*  */



  /* Project */
  #heading{
    font-size: 25px;
  }
  /*  */
}